import { ItemListContextMenu } from 'common/define'
import CListItemContextMenu from 'components/CListItemContextMenu'
import { useContextListOpenFile } from 'context/listopenfile.context'
import React, { useCallback } from 'react'

const arrAction: ItemListContextMenu[] = [
    {key: 'clear-cache', content: 'Clear cache'},
    {key: 'clear-all-selected', content: 'Clear selected files'},
    {key: 'add-folder', content: 'Add folder'},
    {key: 'add-folder-root', content: 'Add folder in root'},
]

type ContextMenuItemProps = Props & {
    handleCloseContext: () => void
}
export default function ContextMenuItem({handleCloseContext}: ContextMenuItemProps): JSX.Element {
    const {callActions, dispatchContext, state: {arrSelected, dataContext}} = useContextListOpenFile();
    const arrDisable = [''];
    if (arrSelected.length === 0) {
        arrDisable.push('clear-all-selected')
    }
    if (dataContext.from === 'folder') {
        arrDisable.push('clear-cache')
    }
    if (dataContext.from === 'file') {
        arrDisable.push('add-folder')
    }
    const handleAction = useCallback((item: ItemListContextMenu) => {
        handleCloseContext();
        const mapActionKey: Record<string, () => void> = {
            'clear-cache': () => callActions({type: 'clear-cache', val: 'single'}),
            'clear-all-selected': () => callActions({type: 'clear-cache', val: 'multiple'}),
            'add-folder': () => dispatchContext({type: 'VISIBLE_DIALOG', payload: {visible: true, data: 'normal'}}),
            'add-folder-root': () => dispatchContext({type: 'VISIBLE_DIALOG', payload: {visible: true, data: 'root'}})
        }
        mapActionKey[item.key]()
    }, [callActions, dispatchContext, handleCloseContext])

    return (
        <CListItemContextMenu data={arrAction} onItemClick={handleAction} arrDisable={arrDisable} />
    )
}