import React from 'react';
import { ItemListContextMenu } from 'common/define';
import { Menu } from 'antd';
import { useContextBoostrap } from 'context/boostrap.context';
import clsx from 'clsx';
import { defaultNotepinCategory } from 'utils/utils';
import { createStyles, ThemeDefine } from 'theme/ThemeProvider';
const {SubMenu} = Menu;


interface PropListItem extends Props {
    data: ItemListContextMenu[];
    onItemClick: (item: ItemListContextMenu) => void;
    isCloseAfterClickItem?: boolean;
    arrDisable?: string[]
}

const PRESET_VIEW = ['Iso', 'Top', 'Bottom', 'Left', 'Right', 'Front', 'Back'];

const useStyles = createStyles((theme: ThemeDefine) => ({
    submenuWrap: {
        zIndex: '2000 !important',
        '& .ant-menu.ant-menu-sub.ant-menu-vertical': {
            minWidth: 'auto !important',
            pointerEvents: 'all !important',
        }
    }
}))

const CListItemContextMenuComponent = ({data, onItemClick, isCloseAfterClickItem = true, className, arrDisable}: PropListItem): JSX.Element | null => {
    const classes = useStyles();
    const {state: {visibleContext}, dispatch} = useContextBoostrap();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const notepinCategory = defaultNotepinCategory.map(v => ({
        type: `pin${v.type}`,
        content: v.type,
        color: v.color
    }))

    if (!data || data.length === 0) {
        return null;
    }

    function handleClickItem(item: ItemListContextMenu) {
        if (onItemClick && typeof onItemClick === 'function') {
            onItemClick(item);
        }
        if (isCloseAfterClickItem && visibleContext) {
            dispatch({type: "CLOSE_CONTEXT"})
        }
    }

    return (
        <Menu 
            className="select-none"
            style={{
                borderRightWidth: 0,
            }}>
            {
                data.map(item => {
                    if(item.content === 'Preset views'){
                        return (
                            <SubMenu 
                                key={item.key} 
                                title={
                                    <span>{item.content}</span>
                                } 
                                className={clsx('item-context-menu-custom', className)} 
                                popupClassName={clsx(
                                    classes.submenuWrap,
                                    'w-24 select-none'
                                )}
                                disabled={arrDisable?.includes(item.key)}>
                                {
                                    PRESET_VIEW.map((val, index) => 
                                        <Menu.Item 
                                            onClick={e => handleClickItem({key: val, content: val} as ItemListContextMenu)} 
                                            key={val}
                                            style={{height:'30px'}}
                                            className={clsx(
                                                className,
                                                'item-context-menu-custom !flex items-center')}>
                                            {val}
                                        </Menu.Item>
                                    )
                                }
                               
                            </SubMenu>
                        )
                    }
                    return (
                        <Menu.Item 
                            key={item.key} 
                            onClick={e => handleClickItem(item)} 
                            disabled={arrDisable?.includes(item.key)}
                            className={clsx('item-context-menu-custom', className,'!h-8 !flex')} 
                            style={{alignItems:'center', margin: '0 auto'}}>
                            {item.content}
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    )
}

const CListItemContextMenu = React.memo(CListItemContextMenuComponent);
export default CListItemContextMenu;