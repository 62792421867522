
import { CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import clsx from 'clsx';
import { StreamFile } from 'common/define';
import { CTooltip } from 'components/CTooltip';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React from 'react';
import Utils from "utils/utils";
type PropsRow = StreamFile;

const RowBodyTableFileComponent = (props: PropsRow): JSX.Element => {
    const {cacheStatus} = props;
    const {state: {arrSelected, searchText, recentUploadFile, loadingFiles}, dispatchContext} = useContextListOpenFile();
    const arrSelectedMapId = arrSelected.map(f => f.baseFileId);

    function onChange(props: StreamFile) {
        dispatchContext({type: 'SELECT_ITEM', payload: props})
    }

    const openFileLocation = () => {
        dispatchContext({type: "OPEN_FILE_LOCATION", payload: props})
    }
    function handleContext(event: React.MouseEvent) {
        dispatchContext({type: 'DATA_CONTEXT', payload: {
            event,
            visible: true,
            from: 'file',
            itemSelected: props
        }})
    }
    return (
        <div 
            className={clsx(
                Utils.convertStatus(cacheStatus) === 'Cached' && 'bg-blue-100',
                recentUploadFile && props.baseFileId === recentUploadFile.baseFileId && 'border-2 border-blue-400',
                'rounded'
            )} onContextMenu={handleContext} >
            {
                props.isDirectory === false &&
                <div className={clsx(
                    arrSelectedMapId.includes(props.baseFileId) && 'bg-gray-100'
                )} onClick={() => onChange(props)}>
                    <div className="grid grid-cols-12">
                        <div className='col-span-6 cursor-pointer'>
                            <div className="grid grid-cols-12">
                                <div className='col-span-1 flex justify-center pt-2'>
                                    {(cacheStatus === 2 && !props.isDirectory) &&
                                        <CTooltip title="Cached">
                                            <CheckCircleOutlined />
                                        </CTooltip>
                                    }
                                    {(cacheStatus === 1 && !props.isDirectory) &&
                                        <CTooltip title="Caching">
                                            <WarningOutlined />
                                        </CTooltip>
                                    }
                                    {((cacheStatus === 0 || cacheStatus === 3) && !props.isDirectory) &&
                                        <CTooltip title="No-cache">
                                            <ExclamationCircleOutlined />
                                        </CTooltip>
                                    }
                                </div>
                                <div className='col-span-11 pt-1'>
                                    <div>
                                        <div>
                                            <p className="mb-0 truncate ...">
                                                {props.filename}
                                            </p>
                                        </div>
                                        <div>
                                            {searchText !== '' &&
                                                <div className="flex justify-start text-blue-500">
                                                    <CTooltip title={props.originalFilePath}>
                                                        <p className="mb-0 hover:underline truncate ..." onClick={(e) => {
                                                            e.stopPropagation()
                                                            openFileLocation()
                                                        }}>{props.originalFilePath}</p>
                                                    </CTooltip>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1' />
                        <div className='col-span-5'>
                            <div className="grid grid-cols-12  pb-1">
                                <div className='col-span-4 cursor-pointer pt-1'>
                                    {Utils.formatTimeAgo(props.createdDate)}
                                </div>
                                <div className='col-span-4 cursor-pointer pt-1'>
                                    {Utils.convertBytes(props.status.size)}
                                </div>
                                <div className='col-span-2 cursor-pointer pt-1'>
                                    {Utils.convertStatus(cacheStatus)}
                                </div>
                                <div className='col-span-2 cursor-pointer pt-1 flex items-center'>
                                    {
                                        loadingFiles.map(v=> v.baseFileId).includes(props.baseFileId) ?
                                            <SyncOutlined spin className="text-xl"/> 
                                            :
                                            <Checkbox
                                                checked={arrSelectedMapId.includes(props.baseFileId)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => onChange(props)}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
const RowBodyTableFile = React.memo(RowBodyTableFileComponent);
export default RowBodyTableFile;