import { RenderType } from "./define";

export class SystemConstants {
    public static NULL_GUID = '00000000-0000-0000-0000-000000000000';

    public static PROTOCOL = 'http';

    public static SERVER_WEB = {
        HOST: 'localhost:11180',
    };
    public static SERVER_STREAM = {
        HOST: 'localhost:11182',
        API: {
            HELP: {
                GET: 'api/help',
            },
            SHUTDOWN: {
                POST: 'api/shutdown',
            },
            STATUS: {
                GET: 'api/status',
            },
            SPAWNS: {
                GET: 'api/spawns',
            },
            SPAWN: {
                POST: 'api/spawn',
            },
            GC: {
                POST: 'api/gc',
            },
            CACHE: {
                POST: 'api/cache'
            },
            FILES: {
                GET: 'api/files',
                DOWNLOAD: 'api/download'
            }
        },
    };


    public static SERVER_CONVERSION = {
        HOST: 'localhost:3001',
        API: {
            CACHE: {
                POST: 'api/StreamFile',
                GET_ALL: 'api/StreamFile',
                GET: 'api/StreamFile',
                GET_LIST_FILE: 'api/StreamFile/item',
                DELETE: (id: string): string => `api/StreamFile/${id}`,
                UPDATE_MODEL_FILE_ID: 'api/StreamFile/modelfileid'
            },
            FILE: {
                UPLOAD: 'api/File/upload',
                ADD: 'api/File/folder'
            }
        },
    };

    public static SERVER_BIM = {
        HOST: '',
        API: {
            MODEL_FILE: {
                GET_ALL: 'api/BIM/modelfile',
                GET_MODEL_FILE_ID: 'api/BIM/modelfileId',
                CREAT_MODEL_FILE_ID: 'api/BIM/import/assemblytree',
                GET_MODEL_STRING_WITH_EX: (modelFileId: string, FileExtension: string): string => `api/BIM/modeltree/${modelFileId}?fileExtension=${FileExtension}`,
                GET_PROPERTY: (prdOccId: string): string => `api/BIM/po/property/${prdOccId}`,
                GET_CADVIEWS: (modelFileId: string): string => `api/BIM/cadview/${modelFileId}`,
                GET_VIEWSAVED: (modelFileId: string): string => `api/Markups/modelfile/views/${modelFileId}`,
                GET_PROPERTYS: `api/BIM/externalprops/getbyextinfo`,
                GET_PROPERTIES_BY_NODE: 'api/BIM/nodesprops/getbynodesinfo',
                GET_EXT_PROPERTY: 'api/BIM/externalprops/getbyextinfos',
                GET_PROPS: 'api/BIM/properties/get',
                GET_FLAT_MODEL_TREE: (modelFileId: string, FileExtension: string): string => `api/BIM/flatmodeltree/${modelFileId}?fileExtension=${FileExtension}`,
            },
            MARKUP_VIEW: {
                POST: (modelFileId: string): string => `api/Markups/markups/${modelFileId}`,
                POST_ARRAY: (modelFileId: string): string => `api/Markups/markups/${modelFileId}/views`,
                GET_ALL: (modelFileId: string): string => `api/Markups/modelfile/markups/${modelFileId}`,
                UPDATE: 'api/Markups/markups',
                UPDATE_ARRAY: (modelFileId: string): string => `api/Markups/markups/${modelFileId}/views`,
                UPDATE_ELEMENT_INT: (uniqueId: string, elementName: string): string => `api/Markups/markups/${uniqueId}/${elementName}/Int32`,
                UPDATE_ELEMENT_STR: (uniqueId: string, elementName: string): string => `api/Markups/markups/${uniqueId}/${elementName}/string`,
                DELETE: (markupViewGuid: string): string => `api/Markups/markups/${markupViewGuid}`,
            },
            MARKUP_3DS: {
                POST: (modelFileId: string): string => `api/Markups/3ds/${modelFileId}`,
                GET_ALL: (modelFileId: string): string => `api/Markups/3ds/${modelFileId}`,
                UPDATE: (modelFileId: string): string => `api/Markups/3ds/${modelFileId}`,
                DELETE: (modelFileId: string): string => `api/Markups/3ds/${modelFileId}`,
            },
            NODE_SETTING: {
                GET_ALL: (modelFileId: string): string => `api/NodeSetting/model/${modelFileId}`,
                POST: (modelFileId: string): string => `api/NodeSetting/model/${modelFileId}`,
                DELETE: (markupViewGuid: string): string => `api/NodeSetting/model/${markupViewGuid}`,
            },
            MARKUP_ENTITIES: {
                GET_ALL: (modelFileId: string): string => `api/MarkupEntity/3ds/${modelFileId}`,
                POST: (modelFileId: string): string => `api/MarkupEntity/3ds/${modelFileId}`,
                UPDATE: (uniqueId: string): string => `api/MarkupEntity/${uniqueId}`,
                DELETE: `api/MarkupEntity/Delete`,
            },
            MARKUP_GROUPS: {
                GET_ALL: (modelFileId: string): string => `api/MarkupGroup/${modelFileId}`,
                POST: (modelFileId: string): string => `api/MarkupGroup/${modelFileId}`,
                UPADTE: (groupId: string): string => `api/MarkupGroup/${groupId}`,
                DELETE: (groupId: string): string => `api/MarkupGroup/${groupId}`,
            },
            VIEWS: {
                GET: (modelFileId: string): string => `api/View/markups/${modelFileId}`,
                POST_UPDATE: (modelFileId: string): string => `api/View/${modelFileId}`,
                DELETE: (uniqueId: string): string => `api/View/${uniqueId}`,
                GET_ALL: 'api/View/getbyfileidrev',
            },
            MARKUPS: {
                GET_ALL: (modelFileId: string): string => `api/MarkupGroup/all/${modelFileId}`,
                GET_ALL_MERGER: 'api/MarkupGroup/all/getbyfileidrev',
                GET_MARKUP_COUNT: 'api/MarkupEntity/count',
                SAVE_ALL: (modelFileId: string): string => `api/MarkupGroup/all/${modelFileId}`,
                SAVE_ALL_MERGER: 'api/MarkupGroup/all',
            },
            BOOKMARKS: {
                GET_ALL: (modelFileId: string): string => `/api/SaveBookmark/model/${modelFileId}`,
                POST: (modelFileId: string): string => `/api/SaveBookmark/model/${modelFileId}`,
                DELETE: (modelFileId: string): string => `/api/SaveBookmark/model/${modelFileId}`,
            },
            ISSUE: {
                POST: 'api/MarkupGroup/issue',
                GET: (markupGroupId: string): string => `api/MarkupGroup/issue/${markupGroupId}`,
            },
            FILESETTING: {
                GET: 'api/FileSetting/getbyfileidrev',
                SAVE: 'api/FileSetting',
                SAVE_ALL: 'api/FileSetting/list',
            },
        },
    };

    public static SERVER_IDENTITY = {
        HOST: '',
        API: {
            IDENTITY: {
                LOGIN: 'api/Account/login',
                REGISTER: 'api/Account/register',
                LOGOUT: 'api/Account/logout',
                SETTING: 'api/Settings',
                GET_ALL_USER: 'api/Account/available',
            },
        },
    };

    public static RENDER_TYPE: { [key: string]: RenderType } = {
        csr: 'csr_session',
        ssr: 'ssr_session',
    };

    public static RENDER: RenderType = SystemConstants.RENDER_TYPE.csr;

    public static SHOWED_LIST_FILE = [
        'pdf', 'docx', 'xlsx', 'pptx', 'hsf', 'obj', 'prc', 'ptx', 'stl', 'vrml', 'wrl',
        'sat', 'dwg', 'dxf', 'model', 'catdrawing', 'cgr', 'dae', 'neu', 'fbx', 'ifczip', 'igs',
        'iges', 'jt', 'prt', 'x_b', 'x_t', 'rfa', 'par', 'sldasm', 'sldprt', 'stp', 'step', 'stp.z',
        'skp', 'xyz', 'idw', 'iam', 'session', 'catpart', 'catproduct', '3dxml', 'asm', 'mi', 'drw',
        'frm', 'pic', 'ifc', 'rvt', '3dm', 'slddrw', 'vue', 'dwf', 'dgn', 'dft', 'cgm', 'ai', 'cg4',
        'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'wmf', 'bmp', 'emf', 'emz', 'pcl', 'zip', 'doc',
        'xls', 'xlt', 'xlw', 'xlts', 'rtf', 'vsd', 'msg', 'ext', 'dwfx',
    ];

    public static VIEWER_MODE = {
        PDF: 'Foxit',
        HOOP: 'Hoops',
    };

    public static EPSILON = 0.000001;

    public static PRINT_SCALE = 1;

    public static DPI = { x: 96, y: 96 };

    public static POINT_SIZE_MAX = 20;

    public static CUTTING_SECTION_OPACITY = 0.3;
    public static POINT_SIZE_DEFAULT = 1;
    public static IS_MOBILE_SIZE = document.documentElement.clientWidth > 320 ? true : false;

    public static SERVER_RFI = {
        PROJECT_COMPANY: {
            GET: (compCode: string, projCode: string): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-rest-api/rest/1/pmrfi?finder=selectProjRfi;compCode="${compCode}",projCode="${projCode}"`,
        },
        COMPANY: {
            GET: (companyCode: string): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-rest-api/rest/1/pmproject?finder=selectByCompany;companyCode="${companyCode}"`,
        },
        SUB_PM_BY_SOV: {
            GET: (compCode: string, contCodeVar: string, jobCodeSOV: string): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-rest-api/rest/1/scsched?finder=selectSOV;CompCodeVar=${compCode},ContCodeVarIN="${contCodeVar}",JobCodeSOV=${jobCodeSOV}`,
        },
        SUB_PM_BY_COMPANY: {
            GET: (compCode: string, contCodeVar: string, jobCode: string, vendorCode: string): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-rest-api/rest/1/scmast?finder=selectByCompany;CompCodeVar=${compCode},ContCodeVar=${contCodeVar},JobCodeVar=${jobCode},VendorCodeVar=${vendorCode}`
        },
        SUBMITTAL: {
            GET: (): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-rest-api/rest/1/pmsubmittal`,
        },
        PCI: {
            GET: (): string => `https://solutionsr12mob.cmicpaas.com/cmictest/cm-rest-api/rest/1/cmmast/`,
        },
        DOC: {
            GET: (doc: string): string => `https://solutionsr12mob.cmicpaas.com/cmictest/pm-doc-rest-api/jersey/v1/downloadDoc/${doc}`,
        }
    }

    public static CMIC_PROPERTY = {
        COST_CODE: {
            GET: (): string => `https://solutionsr12mob.cmicpaas.com/cmictest/jc-rest-api/rest/1/jcjobcostcodes`,
        },
        COST_TYPE: {
            GET_JOB: (): string => `https://solutionsr12mob.cmicpaas.com/cmictest/jc-rest-api/rest/1/jcjobcostcatcodes/`,
            GET_CAT: (): string => `https://solutionsr12mob.cmicpaas.com/cmictest/jc-rest-api/rest/1/jcmastercategories?finder=selectCategory;compCode=10`,
        }
    }
}
