import { DefaultLineFormat, DefaultTextFormat } from "common/define";
import { LineStyle } from "common/type-markup";


export default class UtilsExtend {
    static generateList(startVal: number, endVal: number, interval: number): number[] {
        const list: number[] = [];
        for (let i = startVal; i <= endVal; i += interval) {
            list.push(i);
        }
        return list;
    }
}

export const lineOpacityList: number[] = UtilsExtend.generateList(0, 100, 10).sort((a, b) => a < b ? 1 : -1);
export const lineWeightList: number[] = UtilsExtend.generateList(1, 10, 1);
export const startLineShapeTypeList: number[] = UtilsExtend.generateList(0, 9, 1);
export const lineTypeList: number[] = UtilsExtend.generateList(0, 6, 1);
export const endLineShapeTypeList: number[] = UtilsExtend.generateList(0, 9, 1);

export const defaultLineFormatSetting: DefaultLineFormat = {
    lineColor: { r: 255, g: 0, b: 0, a: 1 },
    lineWeight: 6,
    lineOpacity: 0.5,
    fillColor: { r: 255, g: 148, b: 77, a: 1 },
    fillColorOption: false,
    startLineShapeType: 0,
    endLineShapeType: 0,
    lineStyle: LineStyle.continous,
    rotation: 0,
}

export const defaultTextFormatSetting: DefaultTextFormat = {
    textFontSize: 14,
    textColor: { r: 255, g: 0, b: 0, a: 100 },
    textFontFamily: 'Arial',
    textIsBold: false,
    textIsItalic: false,
    textIsUnderline: false,
}