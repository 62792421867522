/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileApi } from "api/file.api";
import { StreamApi } from "api/stream.api";
import { FileInfo } from "common/define";
import { FoxitState } from "common/define-foxit";
import { GlobalState } from "common/global";
import { Annot, AnnotJson, GlobalMarkupOperator, NoteData } from "common/type-markup";
import { BooleanViewId, NavOperatorState, NumberViewId, PayloadSetListOperator, PayloadSetListOperatorDisable, PayloadSetOperatorActive, RootEpic } from "common/type-state";
import { OperatorPdfType, OperatorType } from "common/type-viewer";
import { merge } from "rxjs";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";
import Utils, { Lodash } from "utils/utils";
import { setEnableSaveBtn } from "./markupToolbar.slice";


interface PdfViewerState {
    loading: {
        [viewId: string]: boolean;
    };
    render: {
        [viewId: string]: boolean;
    };
    operator: {
        [viewId: string]: NavOperatorState;
    };
    currentPageIndex: {
        [viewId: string]: number;
    }
    mode: {
        [viewId: string]: ModePdf
    }
    annotItem: Annot | null;
    currentNote: NoteData | null;
    operatorToolbarActive: GlobalMarkupOperator | undefined;
    enableGoToLink: boolean;
    enableMouseWheel: boolean;
}
interface FetchFilePdf {
    fileInfo: FileInfo;
    pdfUI: PdfUI;
}
export interface RegisterAnnot {
    annotItem: Annot,
    pdfViewer: PdfViewer
}

const initState: PdfViewerState = {
    loading: {},
    render: {},
    operator: {},
    currentPageIndex: {},
    mode: {},
    annotItem: null,
    currentNote: null,
    operatorToolbarActive: undefined,
    enableGoToLink: false,
    enableMouseWheel: true,
}

export const fetchFilePdf = createAsyncThunk(
    'pdfViewer/fetchFile',
    async ({ fileInfo, pdfUI }: FetchFilePdf, { dispatch }) => {
        const { viewId } = fileInfo;
        try {

            dispatch(setLoading({ viewId, payload: true }));
            if (process.env.REACT_APP_IS_STREAM_PDF === 'false') {
                const param = Utils.createParamDownload(fileInfo);
                const response = await StreamApi.downloadFile(param);
                if (response.ok) {
                    const buffer = await response.arrayBuffer();
                    pdfUI.openPDFByFile(buffer);
                } else {
                    dispatch(setLoading({ viewId, payload: false }));
                }
            } else {
                const url = FileApi.getUrlStreamfile(fileInfo)
                pdfUI.openPDFByHttpRangeRequest({
                    range: {
                        url,
                    },
                });
            }
        } catch (error) {
            dispatch(setLoading({ viewId, payload: false }));
        }
    }
);

const mapModeOperator: Record<ModePdf, OperatorPdfType> = {
    'document': 'document-mode',
    'plan': 'plan-mode'
}
const arrModeOperator: OperatorType[] = ['document-mode', 'plan-mode'];

const pdfViewerSlice = createSlice({
    name: 'pdfViewer',
    initialState: initState,
    reducers: {
        setRenderPdfViewer(state, action: PayloadAction<BooleanViewId>) {
            const { viewId, payload } = action.payload;
            state.render[viewId] = payload;
            state.loading[viewId] = false;
            if (payload) {
                state.currentPageIndex[viewId] = 0;
                state.mode[viewId] = 'document';
                GlobalState.mapModePdf.set(viewId, 'document');
                state.operator[viewId].currentOperator = ['pan', 'document-mode']
            }
        },
        setLoading(state, action: PayloadAction<BooleanViewId>) {
            const { viewId, payload } = action.payload;
            state.loading[viewId] = payload;
        },
        setOperatorActive(state, action: PayloadAction<PayloadSetOperatorActive>) {
            const { viewId, operator } = action.payload;
            const currentOperatorState = state.operator[viewId];
            if (!currentOperatorState) {
                const operatorState: NavOperatorState = {
                    currentOperator: [operator],
                }
                state.operator[viewId] = operatorState;
            } else {
                const currentMode = state.mode[viewId] || 'document';
                const operatorMapMode = mapModeOperator[currentMode];
                const currentOperator = [operator, operatorMapMode];
                state.operator[viewId].currentOperator = currentOperator
            }
        },
        setAllListOperatorDisablePdf(state, action: PayloadAction<PayloadSetListOperatorDisable>) {
            const { viewId, listOperator = [] } = action.payload;
            if (state.operator[viewId]) {
                state.operator[viewId].listOperatorDisable = listOperator
            }
        },
        setListOperator(state, action: PayloadAction<PayloadSetListOperator>) {
            const { viewId, listOperator } = action.payload;
            state.operator[viewId].listCurrentOperator = listOperator;
        },
        setCurrentIndex(state, action: PayloadAction<NumberViewId>) {
            const { viewId, payload } = action.payload;
            state.currentPageIndex[viewId] = payload
        },
        deleteStateByViewIdPdfViewer(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            const reDeleteLoading = Utils.unsetPathObject(state.loading, viewId);
            reDeleteLoading && (state.loading = reDeleteLoading);
            const reDeleteRender = Utils.unsetPathObject(state.render, viewId);
            reDeleteRender && (state.render = reDeleteRender);
            const reDeleteOperator = Utils.unsetPathObject(state.operator, viewId);
            reDeleteOperator && (state.operator = reDeleteOperator);
            const reDeleteCurrentPage = Utils.unsetPathObject(state.currentPageIndex, viewId);
            reDeleteCurrentPage && (state.currentPageIndex = reDeleteCurrentPage);
        },
        setAnnotItemAdded(state, action: PayloadAction<AnnotJson | null>) {
            return
        },
        setModePdfActive(state, action: PayloadAction<{ viewId: ViewId, mode: ModePdf }>) {
            const { viewId, mode } = action.payload;
            state.mode[viewId] = mode;
            GlobalState.mapModePdf.set(viewId, mode);
            if (state.operator[viewId]) {
                const operatorMapMode = mapModeOperator[mode];
                const currentState = state.operator[viewId].currentOperator;
                const noModeOp = Lodash.remove([...currentState], op => !arrModeOperator.includes(op));
                state.operator[viewId].currentOperator = [...noModeOp, operatorMapMode]
            }
        },
        setCurrentNote(state, action: PayloadAction<NoteData | null>) {
            state.currentNote = action.payload
        },
        setOperatorToolbarActive(state, action: PayloadAction<GlobalMarkupOperator | undefined>) {
            state.operatorToolbarActive = action.payload;
        },
        resetNavigator(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            state.mode[viewId] = 'document';
            GlobalState.mapModePdf.set(viewId, 'document');
            state.operator[viewId].currentOperator = ['pan', 'document-mode'];
        },
        setEnableGoToLink(state, action: PayloadAction<boolean>) { // for future use
            state.enableGoToLink = action.payload
        },
        setEnableMouseWheel(state, action: PayloadAction<boolean>) {
            state.enableMouseWheel = action.payload
        },
    }
})

const setAnnotItemAdded$: RootEpic = (action$, state$) => action$.pipe(
    filter(setAnnotItemAdded.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const pdfViewer = GlobalState.getPdfViewer(viewActive.viewId);
        if (pdfViewer) {
            const stateManager = pdfViewer.getStateHandlerManager();
            stateManager && stateManager.switchTo(FoxitState.selectTextImage);
            return merge(
                [
                    setEnableSaveBtn(true)
                ]
            )
        }
        return []
    }))




export const PdfViewerActionEpics = [
    setAnnotItemAdded$,
]

export const {
    setRenderPdfViewer,
    setLoading,
    setOperatorActive,
    setListOperator,
    setAllListOperatorDisablePdf,
    setCurrentIndex,
    deleteStateByViewIdPdfViewer,
    setAnnotItemAdded,
    setModePdfActive,
    setCurrentNote,
    setOperatorToolbarActive,
    resetNavigator,
    setEnableGoToLink,
    setEnableMouseWheel,
} = pdfViewerSlice.actions;
export default pdfViewerSlice.reducer;
