/* eslint-disable @typescript-eslint/no-explicit-any */
import {FileInfo, RenderType} from "common/define";
import {SystemConstants} from "common/system-constants";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import Utils from "utils/utils";
import HttpClient from "./http-client";
import {APIHosts, ApiReduxHelpers} from "./reduxHelpers";


export interface DataCreateStream {
    class: RenderType;
    params: {
        modelSearchDirectories: string[];
    }
}

export interface ResponseCreateStream {
    endpoints: { ws: string };
    result: string;
    serviceId: string;
}

class StreamApiController extends ApiReduxHelpers {

    constructor() {
        super();

    }

    ApiHost = APIHosts.Stream;

    createStream(data: DataCreateStream): Observable<ResponseCreateStream> {
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_STREAM.API.SPAWN.POST}`, data).pipe(
            map((res) => res as ResponseCreateStream)
        );
    }

    getUrlImage(fileInfo: FileInfo): string {
        const pathObj = {
            path: `${fileInfo.streamLocation}/${Utils.getFileNameWithoutExtension(fileInfo.filename)}.png`
        }
        const path = Utils.parseUrl(pathObj).toString();
        return `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${path}`;
    }

    getAllFiles(path: string): Observable<any[]> {
        const query = `?${Utils.parseUrl({
            path: `${path}`,
            recursive: 'true',
        })}`
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_STREAM.API.FILES.GET}${query}`).pipe(
            map((res) => res as any)
        );
    }

    downloadFile(file: { path: string }): Promise<any> {
        const param = Utils.parseUrl(file);
        const url = `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${param.toString()}`;
        return fetch(url)
    }

    getContent(file: { path: string }): Observable<any> {
        const param = Utils.parseUrl(file);
        const url = `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${param.toString()}`;
        return HttpClient.get(url).pipe(map((res) => res as any))
    }
}

export const StreamApi = new StreamApiController()
