/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { AnnotAttribute, BookmarkCustom, DocumentNode, StampItem } from "common/define";
import { CustomizeStampIcon, FoxitState, FoxitZoomType, Navigate, PdfStampItem } from "common/define-foxit";
import { GlobalState } from "common/global";
import { Annot, AnnotRect, LineStyle, MarkupFillType, PDFAnnotFlag } from "common/type-markup";
import Utils, { Lodash } from "utils/utils";
import MathHelper from "./math.helper";
import { fromEvent, Observable } from "rxjs";
import { first } from "rxjs/operators";

const minMaxDeltaScale = {
    min: 0.1,
    max: 4,
    delta: 0.1
};

export default class PdfHelper {
    static pageCurrentIndex = 0;
    static currentStateDraw = FoxitState.selectTextImage;

    static mouseDownEvent$: Observable<MouseEvent>;
    static mouseDownEvent: MouseEvent | undefined = undefined;
    static endMouseEvent = false;
    static isEnableMouseWheel = true;
    static currentPageIndex(pdfViewer: PdfViewer): number {
        return pdfViewer.getPDFDocRender().getCurrentPageIndex();
    }
    static async getPdfPage(pdfViewer: PdfViewer, pageIndex: number): Promise<PDFPage> {
        const pdfPage = await pdfViewer.getPDFPageRender(pageIndex).getPDFPage();
        return pdfPage;
    }
    static pageHandler(pdfViewer: PdfViewer, page = PdfHelper.currentPageIndex(pdfViewer)) {
        const pageRender = pdfViewer.getPDFPageRender(page);
        return pageRender?.$handler;
    }
    static drawMode(mode: FoxitState, pdfViewer: PdfViewer): void {
        const stateManager = pdfViewer.getStateHandlerManager();
        stateManager && stateManager.switchTo(mode);
        if (mode === FoxitState.createText) {
            PdfHelper.handleMouseListener(pdfViewer);
        }
    }

    static handleMouseListener(pdfViewer: PdfViewer): void {

        const pageElement = pdfViewer.$ui[0].parentElement;
        PdfHelper.mouseDownEvent$ = fromEvent<MouseEvent>(pageElement, 'mousedown');
        PdfHelper.mouseDownEvent$.pipe(first()).subscribe(v => {
            PdfHelper.mouseDownEvent = v;
        });
    }
    static async zoomExcute(pdfViewer: PdfViewer, ratio: RatioPdf, mouseEvent: React.MouseEvent | null = null) {
        const $pdfUI = pdfViewer.$ui[0].parentElement;
        await PdfHelper.zoomTo(pdfViewer, ratio, $pdfUI, mouseEvent);
    }
    static zoomToExc(pdfViewer: PdfViewer, nextScale: number, location: { x: number, y: number } | null = null): void {
        let p = 0; let d = 0; let h = 0;
        const pdfDocRender = pdfViewer.getPDFDocRender();
        const viewRender = pdfViewer.$ui.parent()[0] as HTMLElement;
        const bounding = viewRender.getBoundingClientRect();
        const { left, top, width, height } = bounding;
        const clientX = location?.x || width / 2 + left;
        const clientY = location?.y || height / 2 + top;
        let w;
        let temp;
        const j = pdfDocRender.pagesRender.viewMode.getVisibleIndexes() || [0];
        for (let i = 0; i < j.length; i++) {
            const c = j[i];
            const a = pdfDocRender.getPDFPageRender(c).$ui.get(0).getBoundingClientRect();
            if (clientX <= a.right && clientX >= a.left && clientY >= a.top && clientY <= a.bottom) {
                p = c;
                w = left - clientX;
                d = clientX - a.left;
                if (d < 0) d = 0;
                temp = top - clientY;
                h = clientY - a.top;
                if (h < 0) h = 0;
                break;
            }
        }
        const S = pdfDocRender.getPDFPageRender(p);
        const D = S.page.reverseDevicePoint([d, h], S.scale, S.getRotation());
        const T = S.page.reverseDeviceOffset([w, temp], nextScale, S.getRotation());
        const params = {
            pageIndex: p,
            x: D[0] + T[0],
            y: D[1] + T[1],
        };
        pdfViewer.zoomTo(nextScale, params)
    }
    static zoomInOut(pdfViewer: PdfViewer, currentScale: number, type: 'in' | 'out'): void {
        let scale: number;
        if (type === 'in') {
            const tempScaleIn = currentScale + minMaxDeltaScale.delta;
            scale = tempScaleIn > minMaxDeltaScale.max ? minMaxDeltaScale.max : tempScaleIn;
        } else {
            const tempScaleIn = currentScale - minMaxDeltaScale.delta;
            scale = tempScaleIn < minMaxDeltaScale.min ? minMaxDeltaScale.min : tempScaleIn;
        }
        PdfHelper.zoomToExc(pdfViewer, scale);
    }
    static async zoomTo(pdfViewer: PdfViewer, ratio: RatioPdf, $ui: HTMLElement, mouseEvent: React.MouseEvent | null) {
        const bouding = $ui.getBoundingClientRect();
        const curElementWidth = bouding.width;
        const curElementHeight = bouding.height;
        const { left, top } = bouding;
        if (ratio === 'fit') {
            PdfHelper.zoomFit(pdfViewer);
        } else {
            let x = left + curElementWidth / 2;
            let y = top + curElementHeight / 2;
            if (mouseEvent) {
                x = mouseEvent.clientX;
                y = mouseEvent.clientY;
            }
            PdfHelper.zoomToExc(pdfViewer, ratio, { x, y });
        }
    }
    static async zoomFit(pdfViewer: PdfViewer, pageIndex?: number): Promise<void> {
        const { offsetWidth: curElementWidth, offsetHeight: curElementHeight } = pdfViewer.$ui[0].parentElement;
        const pageNum = pageIndex ?? PdfHelper.currentPageIndex(pdfViewer);
        const page = await PdfHelper.getPdfPage(pdfViewer, pageNum);
        const width = page.getWidth();
        const height = page.getHeight();
        if (width / curElementWidth > height / curElementHeight) {
            await pdfViewer.zoomTo('fitWidth')
        } else {
            await pdfViewer.zoomTo('fitHeight')
        }
    }
    static zoomByWheel(pdfViewer: PdfViewer, wheelEvent: React.WheelEvent) {
        if (wheelEvent.deltaY >= 1) {
            pdfViewer.eventEmitter.emit('zoom-request', FoxitZoomType.ZoomOut);
        }
        if (wheelEvent.deltaY <= -1) {
            pdfViewer.eventEmitter.emit('zoom-request', FoxitZoomType.ZoomIn);
        }
    }
    static zoomRequest(pdfViewer: PdfViewer | undefined, viewId: ViewId, type: FoxitZoomType) {
        const currentScale = GlobalState.getCurrentScale(viewId);
        if (pdfViewer && currentScale) {
            switch (type) {
                case FoxitZoomType.ZoomIn: {
                    PdfHelper.zoomInOut(pdfViewer, currentScale, 'in')
                    break
                }
                case FoxitZoomType.ZoomOut: {
                    PdfHelper.zoomInOut(pdfViewer, currentScale, 'out')
                    break
                }
                default: break
            }
        }
    }
    static wheelEventHandle(pdfViewer: PdfViewer | undefined, wheelEvent: React.WheelEvent, viewId: ViewId) {
        if (pdfViewer && PdfHelper.isEnableMouseWheel) { // disable pdf mousewheel to enable using wheel in textbox
            const mode = GlobalState.mapModePdf.get(viewId) || 'document';
            if (mode === 'document') {
                if (!wheelEvent.ctrlKey) {
                    if (wheelEvent.deltaY >= 1) {
                        pdfViewer.eventEmitter.emit('navigate-request', Navigate.Next);
                    }
                    if (wheelEvent.deltaY <= -1) {
                        pdfViewer.eventEmitter.emit('navigate-request', Navigate.Previous);
                    }
                }
            } else {
                PdfHelper.zoomByWheel(pdfViewer, wheelEvent)
            }
        }
    }
    static async rotatePage(pdfViewer: PdfViewer, pageNum?: number, rotateVal?: number): Promise<number> {
        const pageIndex = pageNum ?? PdfHelper.currentPageIndex(pdfViewer);
        const pdfPage = await PdfHelper.getPdfPage(pdfViewer, pageIndex);
        let nextRotatation = rotateVal ?? 0;
        if (rotateVal === undefined) {
            const curPageRotation = pdfPage.getRotation();
            nextRotatation = (curPageRotation + 1) % 4;
        }
        await pdfPage.setRotation(nextRotatation);
        return nextRotatation;
    }
    static totalPages(pdfViewer: PdfViewer): number {
        return pdfViewer.getCurrentPDFDoc()?.getPageCount() ?? 0;
    }
    static navigate(pdfViewer: PdfViewer | undefined, isPrevPage: boolean): number {
        if (pdfViewer) {
            const currPageIndex = PdfHelper.currentPageIndex(pdfViewer);
            if (isPrevPage && currPageIndex > 0) {
                const goIndex = currPageIndex - 1;
                PdfHelper.goToPageIndex(pdfViewer, goIndex);
                return goIndex;
            }
            if (!isPrevPage && PdfHelper.totalPages(pdfViewer) - 1 > currPageIndex) {
                const goIndex = currPageIndex + 1;
                PdfHelper.goToPageIndex(pdfViewer, goIndex);
                return goIndex;
            }
        }
        return 0;
    }
    static async goToPageIndex(pdfViewer: PdfViewer, page: number, location?: Point2): Promise<void> {
        if (page === PdfHelper.currentPageIndex(pdfViewer)) return;
        const devicePosition = pdfViewer.getDeviceCurrentPosition();
        const pdfDocRender = pdfViewer.getPDFDocRender();
        const pageCout = PdfHelper.totalPages(pdfViewer);
        let pageIndex = page;
        if (page < 0) pageIndex = 0;
        if (page > pageCout - 1) pageIndex = pageCout - 1;
        const position = location ?? { x: devicePosition.left, y: devicePosition.top };
        await pdfDocRender.goToPage(pageIndex, position, false);
    }
    static canvas2Image(canvasData: any): string {
        const { buffer, width, height } = canvasData;
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.setAttribute('style', 'display: none;');
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.canvas.width = width;
                ctx.canvas.height = height;
                const imgData = ctx.createImageData(width, height);
                const arrayBuffer = new (Uint8ClampedArray || Uint8Array)(buffer);
                imgData.data.set(arrayBuffer);
                ctx.putImageData(imgData, 0, 0);
            }

        }
        return canvas.toDataURL();
    }
    static async getThumbnailPage(pageIndex: number, pdfViewer: PdfViewer) {
        const pdfPage = await PdfHelper.getPdfPage(pdfViewer, pageIndex);
        const thumb = await pdfPage.getThumb();
        const imgUrl = PdfHelper.canvas2Image(thumb);
        return imgUrl
    }
    static canScroll(pdfViewer: PdfViewer) {
        const pdfView = pdfViewer.$ui[0].parentElement;
        const pageView = PdfHelper.pageHandler(pdfViewer)[0].parentElement;
        return (pageView.offsetWidth > pdfView.offsetWidth || pageView.offsetHeight > pdfView.offsetHeight);
    }
    static scrollToRectElement(pdfViewer: PdfViewer, page: number, rectBoundary: Boundary, canScroll: boolean, isSmooth = true) {
        const rectClone = Lodash.cloneDeep(rectBoundary);
        const mainElement = pdfViewer.$ui[0].parentElement as HTMLElement;
        const mainElementRect = mainElement.getBoundingClientRect();
        const pageRender = pdfViewer.getPDFPageRender(page);
        const { $handler } = pageRender;
        const pageHandlerBoundary = $handler[0].getBoundingClientRect();
        const rectWidth = rectClone.right - rectClone.left;
        const rectHeight = rectClone.bottom - rectClone.top;
        rectClone.left += pageHandlerBoundary.left - mainElementRect.left;
        rectClone.top += pageHandlerBoundary.top - mainElementRect.top;
        rectClone.right = rectClone.left + rectWidth;
        rectClone.bottom = rectClone.top + rectHeight;
        const scrollDelta = MathHelper.getScrollPosible(mainElementRect, rectClone, pageHandlerBoundary);
        if (canScroll && mainElement.scrollBy) {
            if (isSmooth) mainElement.scrollBy({ left: scrollDelta.deltaX, top: scrollDelta.deltaY, behavior: 'smooth' });
            else mainElement.scrollBy({ left: scrollDelta.deltaX, top: scrollDelta.deltaY });
        }
    }
    static transformLocation(rect: Boundary, pdfViewer: PdfViewer): Point2 {
        const rectClone = Lodash.cloneDeep(rect);
        const mainElement = pdfViewer.$ui[0].parentElement;
        const mainElementRect = mainElement.getBoundingClientRect();
        const x = (rectClone.left + rectClone.right) / 2;
        const y = (rectClone.top + rectClone.bottom) / 2;
        let deltaY = y - mainElementRect.height / 2;
        deltaY = deltaY > 0 ? deltaY : 0;
        return { x: x - mainElementRect.width / 2, y: deltaY };
    }
    static createStampRect(mousePos: Point2, width: number, height: number, rotation: number): AnnotRect {
        const top = mousePos.y - height;
        const bot = mousePos.y + height;
        const right = mousePos.x + width;
        const left = mousePos.x - width;
        let rect: AnnotRect
        switch (rotation) {
            case 0: {
                rect = { top: top, right: right, bottom: bot, left: left };
                break;
            }
            case 1: {
                rect = { top: left, right: top, bottom: right, left: bot };
                break;
            }
            case 2: {
                rect = { top: bot, right: left, bottom: top, left: right };
                break;
            }
            case 3: {
                rect = { top: right, right: bot, bottom: left, left: top };
                break;
            }
            default: {
                rect = { top: right, right: bot, bottom: left, left: top };
            }
        }
        return rect

    }
    static async goToBookmark(bm: DocumentNode | BookmarkCustom, pdfViewer: PdfViewer, type: 'origin' | 'custom', curPDFScale?: number) {
        if (bm.page !== null) {
            let rect: Boundary;
            let scale: number;
            if (type === 'origin') {
                const { top, left } = bm as DocumentNode;
                rect = await MathHelper.getDeviceRect(pdfViewer, bm.page, { top, left, right: left + 10, bottom: top + 10 });
                scale = curPDFScale ?? 0
            } else {
                const { rect: reactCustom, scale: scaleCustom } = bm as BookmarkCustom;
                rect = reactCustom;
                scale = scaleCustom as number
            }
            await PdfHelper.zoomExcute(pdfViewer, scale);
            await Utils.delay(50);
            if (PdfHelper.currentPageIndex(pdfViewer) !== bm.page) {
                const position = PdfHelper.transformLocation(rect, pdfViewer);
                await PdfHelper.goToPageIndex(pdfViewer, bm.page, position);
            } else {
                PdfHelper.scrollToRectElement(pdfViewer, bm.page, rect, PdfHelper.canScroll(pdfViewer));
            }
        }
    }
    static getRect(pdfViewer: PdfViewer) {
        const page = PdfHelper.currentPageIndex(pdfViewer);
        const pdfRect = pdfViewer.$ui[0].parentElement.getBoundingClientRect();
        const pageRender = pdfViewer.getPDFPageRender(page);
        const { $handler } = pageRender;
        const pageRect = $handler[0].parentElement.getBoundingClientRect();
        let deltaX = pdfRect.left - pageRect.left;
        let deltaY = pdfRect.top - pageRect.top;
        const pageWidth = pageRect.right - pageRect.left;
        const pageHeight = pageRect.bottom - pageRect.top;
        let width = pdfRect.right - pdfRect.left;
        let height = pdfRect.bottom - pdfRect.top;
        width = pageWidth < width ? pageWidth : width;
        height = pageHeight < height ? pageHeight : height;
        deltaX = deltaX < 0 ? 0 : deltaX;
        deltaY = deltaY < 0 ? 0 : deltaY;
        const rect: Rectangle = {
            x: deltaX, y: deltaY, width, height,
        };
        return rect;
    }
    public static getAnnotLineStylePattern(lineStyle: LineStyle, ratio: number) {
        let style = 0;
        let dashes: number | number[];
        const sizeRatio = ratio || 1;
        function mapSize(srcArr: number[], scale: number) {
            return srcArr.map((p) => p * scale);
        }
        switch (lineStyle) {
            case LineStyle.continous: {
                style = 0;
                dashes = [];
                break;
            }
            case LineStyle.smallDash: {
                style = 1;
                dashes = mapSize([8, 8, 8, 8], sizeRatio);
                break;
            }
            case LineStyle.largeDash: {
                style = 1;
                dashes = mapSize([16, 16, 16, 16], sizeRatio);
                break;
            }
            case LineStyle.center: {
                style = 1;
                dashes = mapSize([16, 3, 3, 3], sizeRatio);
                break;
            }
            case LineStyle.phantom: {
                style = 1;
                dashes = mapSize([16, 3, 3, 3, 3, 3], sizeRatio);
                break;
            }
            case LineStyle.zigzag: {
                style = 3;
                dashes = 1;
                break;
            }
            case LineStyle.cloud: {
                style = 5;
                dashes = 1;
                break;
            }
            default: {
                style = 0;
                dashes = [];
                break;
            }
        }
        return { style, dashes };
    }
    public static getStamps(pdfUI: PdfUI): PdfStampItem[] {
        const allIcons = pdfUI.getAnnotationIcons("stamp", false);
        const iconNames: PdfStampItem[] = [];
        for (const categoryKey in allIcons) {
            const category = allIcons[categoryKey];
            for (const name in category) {
                let url = '';
                switch (categoryKey) {
                    case 'Dynamic': {
                        url = `https://webviewer-demo.foxitsoftware.com/lib/stamps/en-US/DynamicStamps/${name}.svg`;
                        break
                    }
                    case 'SignHere': {
                        url = `https://webviewer-demo.foxitsoftware.com/lib/stamps/en-US/SignHere/${name}.svg`;
                        break
                    }
                    case 'Static': {
                        url = `https://webviewer-demo.foxitsoftware.com/lib/stamps/en-US/StandardStamps/${name}.svg`;
                        break
                    }
                }
                iconNames.push({
                    category: categoryKey,
                    name,
                    fileType: category[name].fileType,
                    url: url,
                    localURL: category[name].url
                });
            }
        }
        return iconNames
    }

    public static setAnnotsAttribute(pdfUI: PdfUI, annotAttribute: AnnotAttribute) {
        const {
            textFontSize,
            textColor,
            lineColor,
            lineOpacity,
            lineWeight,
            fillColor,
            fillColorOption,
            startLineShapeType,
            endLineShapeType,
            ratio,
            lineStyle,
            rect,
        } = annotAttribute;
        const borderSize = ratio ? lineWeight * ratio : lineWeight;
        const fontColor = textColor;
        const borderColor = lineColor;
        const fillType = fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None;
        let opacity = 1;
        const textboxVisibility = opacity;
        const fontSize = ratio ? textFontSize * ratio : textFontSize;

        const borderWidth = borderSize < 1 ? 1 : borderSize;
        const fontColorNum = MathHelper.parseColor(MathHelper.RGBToHexA(fontColor));
        const borderColorNum = MathHelper.parseColor(MathHelper.RGBToHexA(borderColor));
        let fillColorNum = MathHelper.parseColor(MathHelper.RGBToHexA(fillColor));
        const fillColorTemp = fillColorNum;
        if (fillType === MarkupFillType.None) fillColorNum = 0;
        const lineStyleInfo = PdfHelper.getAnnotLineStylePattern(lineStyle, ratio);
        const { style, dashes } = lineStyleInfo;
        let borderInfo: any = null;
        if (lineStyle !== LineStyle.cloud) {
            borderInfo = { width: borderWidth, style, dashes };
        } else {
            borderInfo = { width: borderWidth };
        }
        pdfUI.setDefaultAnnotConfig((type: any, intent: any) => {
            if (type !== 'highlight' && fillType === MarkupFillType.None) {
                opacity = 1;
            }
            const config = {
                borderInfo,
                color: borderColorNum,
                fillColor: fillColorNum,
                opacity,
            };
            switch (type) {
                case 'freetext': {
                    let freetextBorderInfo = { ...borderInfo };
                    if (lineStyle === LineStyle.cloud) {
                        freetextBorderInfo = {
                            width: borderWidth, style: LineStyle.cloud, dashes, ratio,
                        };
                    }
                    const freetextConfig = {
                        borderInfo: freetextBorderInfo,
                        color: borderColorNum,
                        fillColor: textboxVisibility === 0 ? 0 : fillColorNum,
                        opacity,
                        rect,
                    };
                    if (!textboxVisibility && !intent) {
                        freetextConfig.borderInfo.width = 0;
                    }
                    return freetextConfig;
                }
                case 'line': {
                    const startStyle = { startStyle: startLineShapeType };
                    const endStyle = { endStyle: endLineShapeType };
                    config.fillColor = 0;
                    config.opacity = lineOpacity;
                    return Object.assign(config, startStyle, endStyle);
                }
                case 'text':
                case 'ink': {
                    config.color = MathHelper.getDecimalFromColor(MathHelper.RGBToHexA(borderColor));
                    config.fillColor = fillColorOption ? fillColorTemp : 0;
                    if (fillColor?.a && lineColor?.a)
                        config.opacity = fillColorOption ? lineOpacity : 1;
                    return config;
                }
                case 'polyline':
                    config.fillColor = fillColorOption ? fillColorTemp : 0;
                    if (fillColor?.a && lineColor?.a)
                        config.opacity = fillColorOption ? lineOpacity : 1;
                    return config;
                case 'polygon':
                    config.fillColor = fillColorOption ? fillColorTemp : 0;
                    if (fillColor?.a && lineColor?.a)
                        config.opacity = fillColorOption ? lineOpacity : 1;
                    return config;
                case 'highlight':
                    config.color = fillColorTemp;
                    config.opacity = 1;
                    return config;
                case 'square':
                    if (fillColor?.a && lineColor?.a)
                        config.opacity = fillColorOption ? lineOpacity : 1;
                    return config;
                case 'circle':
                    if (fillColor?.a && lineColor?.a)
                        config.opacity = fillColorOption ? lineOpacity : 1;
                    return config;
                default: {
                    return config;
                }
            }
        });
    }

    public static getFoxitProp(pdfViewer: PdfViewer) {
        return pdfViewer.getFoxitProp(); // Get pdf core like default...
    }

    public getAnnotLineStylePattern(lineStyle: LineStyle, ratio: number) {
        let style = 0;
        let dashes = null;
        const sizeRatio = ratio || 1;
        function mapSize(srcArr: number[], scale: number) {
            return srcArr.map((p) => p * scale);
        }
        switch (lineStyle) {
            case LineStyle.continous: {
                style = 0;
                dashes = [];
                break;
            }
            case LineStyle.smallDash: {
                style = 1;
                dashes = mapSize([2, 4, 2, 4], sizeRatio);
                break;
            }
            case LineStyle.largeDash: {
                style = 1;
                dashes = mapSize([8, 4, 8, 4], sizeRatio);
                break;
            }
            case LineStyle.center: {
                style = 1;
                dashes = mapSize([8, 4, 2, 4], sizeRatio);
                break;
            }
            case LineStyle.phantom: {
                style = 1;
                dashes = mapSize([8, 4, 2, 4, 2, 4], sizeRatio);
                break;
            }
            case LineStyle.zigzag: {
                style = 3;
                dashes = 1;
                break;
            }
            case LineStyle.cloud: {
                style = 5;
                dashes = 1;
                break;
            }
            default: {
                style = 0;
                dashes = [];
                break;
            }
        }
        return { style, dashes };
    }

    static async removeAnnot(annotID: any, pdfViewer: PdfViewer | undefined) {
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage();
            pdfPage.removeAnnotById(annotID);
        }
    }
    static async redrawAnnots(pdfViewer: PdfViewer | undefined, hideOrShow: boolean) {
        if (pdfViewer) {
            await pdfViewer.redraw(hideOrShow);
        }
    }

    static async removeAllAnnots(pdfViewer: PdfViewer, pageIndex: number) {
        const pdfPage = await PdfHelper.getPdfPage(pdfViewer, pageIndex);
        pdfPage.info && await pdfPage.removeAllAnnot()
    }
    static async removeFilteredAnnots(pdfViewer: PdfViewer, annots: any[]) {
        const currPage = pdfViewer.getPDFDocRender()?.getCurrentPageIndex();
        const pdfPage = await PdfHelper.getPdfPage(pdfViewer, currPage);
        annots.forEach(annot => pdfPage.removeAnnotById(annot.name));
    }

    static async setAnnotVisibleStatus(pdfUI: PdfUI, status: PDFAnnotFlag) {
        const annotManager = await pdfUI.getAnnotManager()
        annotManager.setViewerAnnotFlag((annot: any) => status);
        await pdfUI.redraw(false);
    }
    static async setListAnnotsFlags(pdfUI: PdfUI, listId: string[]) {
        const annotManager = await pdfUI.getAnnotManager();
        annotManager.setViewerAnnotFlag((annot: any) => {
            const canShow = listId.includes(annot.getId());
            if (canShow) return PDFAnnotFlag.Print;
            return PDFAnnotFlag.Hidden;
        });
        await pdfUI.redraw(false);
    }

    static async removeAnnotByID(pdfViewer: PdfViewer, page: number, id: string) {
        const pdfPage = await PdfHelper.getPdfPage(pdfViewer, page);
        const rawAnnots = await pdfPage.getAnnots() as Annot[];
        const annots = rawAnnots.filter(v => v.isMarkup() && v.id === id);
        annots.forEach(v => {
            v.setFlags(PDFAnnotFlag.Hidden);
            (pdfPage as any).removeAnnot(v);
        })
    }

}