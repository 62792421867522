/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StreamApi, DataCreateStream, ResponseCreateStream } from "api/stream.api";
import { FileInfo, FlatTreeNode, Views } from "common/define";
import { GlobalState } from "common/global";
import { SystemConstants } from "common/system-constants";
import { CuttingPlaneState, NavOperatorState } from "common/type-state";
import { CuttingType, OperatorType, PlaneBoxChild } from "common/type-viewer";
import { selectionFlag } from "container/viewer3d/extends/CSelectionManager";
import { MarkupAction } from "container/viewer3d/markup/markup.action";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import Utils, { Lodash } from "utils/utils";
import _ from 'lodash';

/** interface */
export interface Viewer3DState {
    operator: {
        [viewId: string]: NavOperatorState
    };
    cuttingPlane: {
        [viewId: string]: CuttingPlaneState
    },
    planeBoxChildState: {
        [viewId: string]: {
            planeBoxChild: PlaneBoxChild[],
            isClipping: boolean
        }
    },
    loadingViewer: {
        [viewId: string]: boolean;
    },
    webViewerState: {
        [viewId: string]: boolean;
    },
    modelStructureReady: {
        [viewId: string]: boolean
    },
    selectionItem: {
        [viewId: string]: {
            nodes: NodeId[],
            type: SelectedNodeType
        }
    },
    hidedItem: {
        [viewId: string]: NodeId[]
    },
    removeAllHidedItem: boolean;
}
export interface PayloadCreateWebViewer {
    response: ResponseCreateStream,
    containerId: string,
    modelName: string;
    viewId: string;
}
export interface MapViewsItem {
    views: Views,
    viewId: string;
}
interface ResultExplodeState {
    currentValueExplode?: number;
    currentOperator?: OperatorType[]
}
export interface ResultInitOperatorState {
    viewId?: ViewId;
    operatorState: NavOperatorState;
    cuttingPlaneState: CuttingPlaneState
}
export interface ResultResetOperatorState {
    operatorState: NavOperatorState;
    viewId?: ViewId
}

export type PersistenIdOfNode = { _nodeId: number; _persistentId: string | undefined };
/** function */
export const DefaultDisableCutting: CuttingType[] = ['reverse-plane-x', 'reverse-plane-y', 'reverse-plane-z', 'toggle-plane', 'toggle-section', 'rotate-box'];
function createWebViewerFunc({ response, containerId, modelName, viewId }: PayloadCreateWebViewer): { viewId: ViewId, viewer: Communicator.WebViewer } {
    const viewer = new Communicator.WebViewer({
        model: modelName,
        containerId: containerId,
        endpointUri: response.endpoints.ws,
        calculateDefaultViewAxes: false,
        streamingMode: Communicator.StreamingMode.Interactive
    });
    viewer.start();
    return { viewId, viewer };
}
function createDataStream(location: string): DataCreateStream {
    return {
        class: SystemConstants.RENDER,
        params: {
            modelSearchDirectories: [location]
        }
    }
}
export function UnHightLightFaceLightPoint(webViewerActive: Communicator.WebViewer): void {
    if (webViewerActive) {
        if (webViewerActive.selectionManager.getHighlightFaceElementSelection())
            webViewerActive.selectionManager.setHighlightFaceElementSelection(false);
        if (webViewerActive.selectionManager.getHighlightLineElementSelection())
            webViewerActive.selectionManager.setHighlightLineElementSelection(false);
        if (webViewerActive.selectionManager.getHighlightPointElementSelection())
            webViewerActive.selectionManager.setHighlightPointElementSelection(false);
    }
}
/** export functions, variable */
export function GetNodeInTreePerNode(
    webViewerActive: Communicator.WebViewer,
    modelFileId: string,
    selectionArray: number[],
    treeMapData: Map<string, FlatTreeNode>
): Map<number, number> {
    const mapRet = new Map<number, number>();
    const arrNodeParentSelect = getParentNodeOfArrNode(selectionArray, webViewerActive, 100);
    arrNodeParentSelect.forEach(listNodes => {
        const nodeInTree = listNodes.find(nodeId => treeMapData.has(String(nodeId)));
        if (nodeInTree !== undefined) {
            mapRet.set(listNodes[0], nodeInTree);
        }
    });
    return mapRet;
}
export function UnSelectNodes(webViewer: Communicator.WebViewer, nodes: number[]): void {
    const selectionManager = webViewer.selectionManager;
    const nodeSelectionItems = selectionManager.getResults();
    nodeSelectionItems.forEach(nodeSelectionItem => {
        if (nodes.includes(nodeSelectionItem.getNodeId())) {
            selectionManager.remove(nodeSelectionItem);
        }
    });
    // fix unselect node in model tree when draw mode is Ghost
    // if (webViewer.view.getDrawMode() === Communicator.DrawMode.Ghost) {
    //     ModelHelper.setSelectionGhostMode(webViewer);
    // }
    // webViewer.redraw();
}

export function getPersistentIdsMapInNodeIds(viewActive: Communicator.WebViewer, viewId: string, selectionNodeIds: number[])
    : Map<number, PersistenIdOfNode[]> {
    const persistentMapRet: Map<number, PersistenIdOfNode[]> = new Map<number, PersistenIdOfNode[]>();
    const arrNodeParentSelect = getParentNodeOfArrNode(selectionNodeIds, viewActive, 100);
    for (let index = 0; index < arrNodeParentSelect.length; index++) {
        const nodeParentIDs = arrNodeParentSelect[index];
        const basenode = nodeParentIDs[0];

        const listPersistentId: PersistenIdOfNode[] = [];
        for (let index = 0; index < nodeParentIDs.length; index++) {
            const nodeID = nodeParentIDs[index];
            const persistentID = GlobalState.GetPesistentsIdByNodeIds(viewId, nodeID);
            listPersistentId.push({ _nodeId: nodeID, _persistentId: persistentID });
        }
        persistentMapRet.set(basenode, listPersistentId)
    }
    return persistentMapRet
}

export function getNodeIdsByPersistentIds(webViewer: Communicator.WebViewer, viewId: ViewId, persistentIds: string[]): number[] {
    const nodeIds: number[] = [];
    for (let index = 0; index < persistentIds.length; index++) {
        const persistentId = persistentIds[index];
        const nodeIdsInSheet = GlobalState.getNodeidsByPersistanceId(webViewer, viewId, persistentId);
        if (nodeIdsInSheet) {
            for (let index2 = 0; index2 < nodeIdsInSheet.length; index2++) {
                const nodeIdInSheet = nodeIdsInSheet[index2];
                const type = webViewer.model.getNodeType(nodeIdInSheet);
                if (type !== Communicator.NodeType.AssemblyNode) {
                    nodeIds.push(nodeIdInSheet);
                }
            }
        }
    }
    return nodeIds
}
export function Update3dViewerSelection(webViewer: Communicator.WebViewer, selectionArray: number[]): void {
    const curSelections = webViewer.selectionManager.getResults().map(node => node.getNodeId());
    const removeIds = _.difference(curSelections, selectionArray);//curSelections.filter(node => !selectionArray.includes(node));
    const addIds = _.difference(selectionArray, curSelections);//selectionArray.filter(node => !curSelections.includes(node));
    if (removeIds.length > 0) UnSelectNodes(webViewer, removeIds);
    if (addIds.length > 0) SelectNodesWithTriggerFlag(webViewer, addIds, false);
}
export function ClearSelectNodesWithoutTrigger(webViewer: Communicator.WebViewer): void {
    const selectionManager = webViewer.selectionManager;
    selectionManager.clear(false);
}

export async function SelectNodesWithTriggerFlag(webViewer: Communicator.WebViewer, nodes: number[], triggerSelection = true): Promise<void> {
    return new Promise((resolve) => {
        const selectionManager = webViewer.selectionManager;
        selectionManager.addFlag(selectionFlag.SelectByCode);
        SelectNodes(webViewer, nodes, triggerSelection);
        selectionManager.removeFlag(selectionFlag.SelectByCode);
        webViewer.redraw();
    });
}

export function SelectNodes(webViewer: Communicator.WebViewer, nodes: number[], triggerSelection: boolean): void {

    const selectionManager = webViewer.selectionManager;
    const selections = selectionManager.getResults().map(node => node.getNodeId());
    // nodes.forEach(node => {
    //     if (!selections.includes(node)) {
    //         selectionManager.selectNode(node, Communicator.SelectionMode.Add);
    //         webViewer.redraw();
    //     }
    // });
    // const selectedNodes = nodes.filter(id => ![ Communicator.NodeType.AssemblyNode, Communicator.NodeType.Unknown ].includes(webViewer.model.getNodeType(id)));
    const diff = _.difference(nodes, selections);
    let resultSelected: Communicator.SelectionType | null = null;
    diff.forEach(node => {
        const res: Communicator.SelectionType = selectionManager.selectNode(node, Communicator.SelectionMode.Add);
        if (resultSelected === null && res === Communicator.SelectionType.None) resultSelected = res;
    });
    // select a node not shown on model => alert a tooltip for user
    if (resultSelected === null) return;
    GlobalState.tooltipNodeSelection$.next(true);
}
export function setNodesHighlighted(webViewer: Communicator.WebViewer, nodes: number[]): void {
    try {
        webViewer.model.setNodesHighlighted([webViewer.model.getAbsoluteRootNode()], false);
        const nodesNumber = nodes.filter(num => !isNaN(num));
        if (nodesNumber.length > 0) {
            webViewer.model.setNodesHighlighted(nodesNumber, true);
            const selectionManager = webViewer.selectionManager;
            selectionManager.addNodeSelectWithoutTrigger(nodesNumber)
        }
    } catch (error) {
        console.info(error)
    }
}
export function unsetNodesHighlighted(webViewer: Communicator.WebViewer, nodes: number[]): void {
    try {
        const nodesNumber = nodes.filter(num => !isNaN(num));
        if (nodesNumber.length > 0) {
            webViewer.model.setNodesHighlighted(nodesNumber, false);
        }
        const selectionManager = webViewer.selectionManager;
        selectionManager.addNodeSelectWithoutTrigger([])
    } catch (error) {
        console.info(error)
    }
}
export function createDataStream$(fileInfo: FileInfo, containerId: string): Observable<string> {
    const locationTrim = Utils.regexStreamLocation(fileInfo.streamLocation);
    const data = createDataStream(locationTrim);
    return StreamApi.createStream(data).pipe(
        switchMap((response: ResponseCreateStream) => {
            GlobalState.rootStreamLocation = {
                location: locationTrim,
                response: response
            }
            return createWebViewer$(fileInfo, response, containerId);
        })
    )
}

export function createWebViewer$(fileInfo: FileInfo, response: ResponseCreateStream, containerId: string): Observable<string> {
    const modelName = Utils.getModelNameByStreamLocation(fileInfo);
    const payload: PayloadCreateWebViewer = {
        response,
        containerId,
        modelName,
        viewId: fileInfo.viewId
    };
    const { viewId, viewer } = createWebViewerFunc(payload);
    GlobalState.map3DViewer.set(viewId, viewer);
    const markupAction = new MarkupAction(viewer);
    markupAction.modelFileId = fileInfo.modelFileId;
    GlobalState.markupActionMap.set(viewId, markupAction);
    return new Observable<string>((obser) => {
        viewer.setCallbacks({
            assemblyTreeReady: () => {
                obser.next(viewId);
                obser.complete();
            }
        })
    })
}
export function setExplodeState(value: number, stateOperator: NavOperatorState): ResultExplodeState {
    const result: ResultExplodeState = {}
    result.currentValueExplode = value;
    const currentStateOperator = [...stateOperator.currentOperator];
    if (value > 0) {
        if (currentStateOperator) {
            if (!currentStateOperator.includes('explode')) {
                const finalOperator = new Set<OperatorType>([...currentStateOperator, 'explode']);
                result.currentOperator = Array.from(finalOperator)
            }
        }
    } else {
        result.currentOperator = Lodash.remove(currentStateOperator, op => op !== 'explode');
    }
    return result
}

export function updateCuttingPlaneState(
    cuttingState: CuttingPlaneState,
    currentOperator: OperatorType[]
): OperatorType[] | undefined {
    const plane: CuttingType[] = ['plane-x', 'plane-y', 'plane-z', 'plane-box'];
    let result: OperatorType[] | undefined = undefined;
    const cloneCurrentOperator = [...currentOperator];
    if (cuttingState.active?.find(c => plane.includes(c))) {
        if (!currentOperator.includes('cutting-plane')) {
            result = [...cloneCurrentOperator, 'cutting-plane'];
        }
    } else {
        if (currentOperator.includes('cutting-plane')) {
            result = Lodash.remove(cloneCurrentOperator, c => c !== 'cutting-plane');
        }
    }
    return result
}
export function initOperatorStateObser(isInViewOnly: boolean): Observable<ResultInitOperatorState> {
    return new Observable<ResultInitOperatorState>(obser => {
        const operatorState: NavOperatorState = {
            currentOperator: ['select', 'drawMode-shaded'],
            listOperatorDisable: isInViewOnly ? ['save'] : []
        };
        const cuttingState: CuttingPlaneState = {
            disable: DefaultDisableCutting
        }
        obser.next({
            operatorState,
            cuttingPlaneState: cuttingState
        });
        obser.complete();
    })
}
export function resetOperatorStateObser(isInViewOnly: boolean): Observable<ResultResetOperatorState> {
    return new Observable<ResultResetOperatorState>(obser => {
        const operatorState: NavOperatorState = {
            currentOperator: ['select', 'drawMode-shaded-wire'],
            listOperatorDisable: isInViewOnly ? ['save'] : []
        };
        obser.next({ operatorState });
        obser.complete();
    })
}
export function removeItemInArrHide(currentItems: NodeId[], arrRemove: NodeId[]): NodeId[] {
    const cloneCurrentItems = [...currentItems];
    const cloneArrRemove = [...arrRemove];
    return Lodash.remove(cloneCurrentItems, f => !cloneArrRemove.includes(f))
}

export function getParentNodeOfLastNode(nodes: number[], viewer: Communicator.WebViewer, numberGet = 2): number[] {
    const lastNode = nodes[nodes.length - 1];
    if (lastNode) {
        const arrResult = [lastNode];
        let currNode = lastNode;
        for (let i = 0; i < numberGet; i++) {
            const parent = viewer.model.getNodeParent(currNode);
            if (parent) {
                arrResult.push(parent);
                currNode = parent
            }
        }
        return arrResult
    }
    return []
}
export function getParentNodeOfArrNode(nodes: number[], viewer: Communicator.WebViewer, numberGet = 2): number[][] {
    if (nodes.length > 0) {
        const result = nodes.reduce<number[][]>((re, curr) => {
            let currNode = curr;
            const arr = [curr];
            for (let i = 0; i < numberGet; i++) {
                const parent = viewer.model.getNodeParent(currNode);
                if (parent) {
                    arr.push(parent);
                    currNode = parent
                }
            }
            arr.length > 0 && re.push(arr);
            return re
        }, [])
        return result
    }
    return []
}
