/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { ConversionApi } from "api/conversion.api";
import { StreamApi } from "api/stream.api";
import { ConvertOptions, FileInfoResponse, PayloadOriginFile, StreamFile } from "common/define";
import { GlobalState } from "common/global";
import { isNull } from "lodash";
import { MainOpenField } from "pages/dashboard/TableFile";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import Utils from "utils/utils";

export function sortListFiles(list: StreamFile[]) {
    const all = list.filter(val => val.isDirectory).sort((a, b) => a.filename.localeCompare(b.filename));
    const files = list.filter(val => !val.isDirectory).sort((a, b) => a.filename.localeCompare(b.filename));
    return [...all, ...files];
}

export function sortByField(list: StreamFile[], type: MainOpenField, isAscending: boolean) {
    switch (type) {
        case MainOpenField.Name:
            if (isAscending) {
                return sortListFiles(list);
            }
            else {
                return list.sort((a, b) => {
                    return b.filename.localeCompare(a.filename);
                })
            }
        case MainOpenField.CachedDate:
            return list.sort((a, b) => {
                const aCreatedDate = (a.createdDate === '' || isNull(a.createdDate)) ? 0 : new Date(a.createdDate).getTime();
                const bCreatedDate = (b.createdDate === '' || isNull(b.createdDate)) ? 0 : new Date(b.createdDate).getTime();
                if (isAscending) {
                    return bCreatedDate - aCreatedDate;
                }
                else {
                    return aCreatedDate - bCreatedDate;
                }
            })
        case MainOpenField.Size:
            return list.sort((a, b) => {
                if (isAscending) {
                    return a.status?.size - b.status?.size;
                }
                else {
                    return b.status?.size - a.status?.size;
                }
            })
        case MainOpenField.Status:
            return list.sort((a, b) => {
                const aStatus = Utils.convertStatus(a.cacheStatus);
                const bStatus = Utils.convertStatus(b.cacheStatus);
                if (isAscending) {
                    return aStatus.localeCompare(bStatus)
                }
                else {
                    return bStatus.localeCompare(aStatus)
                }
            })
        default:
            return list;
    }

}

export async function loadFiles() {
    const dashboardHelper = new DashboardHelper();
    try {
        await dashboardHelper.initListFiles();
        const all = dashboardHelper.getAllFiles();
        return all;
    } catch (error) {
        return null
    }
}
export async function cachedFiles(listFile: StreamFile[], currentPath: string, searchText: string) {
    const allUpdate: StreamFile[] = [];
    // Cache result(s) of selected list
    const updates: StreamFile[] = [];
    // Cached file(s) in selected list
    const rets: StreamFile[] = [];
    const all = GlobalState.getAllFile();
    const dashboardHelper = new DashboardHelper();
    if (listFile.length === 0) return rets;
    const listFilePromise = listFile.map(async val => {
        // if selected file is not cache
        if (val.cacheStatus === 0 || val.cacheStatus === 3) {
            const converOptions: ConvertOptions = {
                    convert3DModel: 1,
                    convert2DSheet: 0,
                    extractProperties: 1,
                    childModels: 0
            }
            const finalVal = {
                ...val,
                convertOptions: converOptions
            }
            const file = await dashboardHelper.cacheFile(finalVal);
            // After caching success
            if (file.cacheStatus) {
                if (file.id) {
                    const fileUpdateCache: StreamFile = {
                        ...val,
                        cacheStatus: file.cacheStatus,
                        id: file.id,
                        modelFileId: file.modelFileId,
                        createdDate: file.createdDate ? file.createdDate?.toString() : new Date().toISOString()
                    };
                    if (file.cacheStatus === 2 && file.originalSize !== 0) rets.push(fileUpdateCache);
                    updates.push(fileUpdateCache);
                }
                else {
                    const fileUpdateCache: StreamFile = { ...val, cacheStatus: file.cacheStatus };
                    if (file.cacheStatus === 2 && file.originalSize !== 0) rets.push(fileUpdateCache);
                    updates.push(fileUpdateCache);
                }
            }
        }
        // If selected file is cached
        else {
            if (val.cacheStatus === 2) rets.push(val);
            updates.push(val);
        }
    })
    await Promise.all(listFilePromise);
    all.forEach(v => {
        const item = updates.find(val => val.baseFileId === v.baseFileId);
        allUpdate.push(item || v);
    })
    GlobalState.setAllFile(allUpdate);
    const list = GlobalState.sortFiles(currentPath, searchText);
    GlobalState.updateListFiles(list);
    return rets;
}

export async function uploadFiles(data: any, path: string) {
    const dashboardHelper = new DashboardHelper();
    const file = await dashboardHelper.uploadFile(data, path);
    return file;
}

export function openFileHelper(listFile: StreamFile[]) {
    if (listFile.length > 0) {
        const mapStream = mapStreamFile(listFile);
        const param = {
            fileList: JSON.stringify(mapStream)
        }
        const urlParam = Utils.parseUrl(param);
        window.open(`./mainviewer?${urlParam}`, '_blank');

    }
}

export function mapStreamFile(listFile: StreamFile[]): PayloadOriginFile[] {
    return listFile.map(f => {
        const map: PayloadOriginFile = {
            baseFileId: f.baseFileId,
            baseMajorRev: f.baseMajorRev,
            baseMinorRev: f.baseMinorRev,
            fileName: f.filename,
        };
        return map;
    });
}
const reg = new RegExp(/[^.]+\.[^.]+\.[^.]+$/);
export default class DashboardHelper {
    private alls: StreamFile[] = [];
    static clearCaches(ids: string[]) {
        return from(ids).pipe(mergeMap((id) => ConversionApi.clearFileCached(id)));
    }

    static getPath(): string {
        const lstPath: string[] = ['.'];
        return lstPath.join('/');
    }
    getAllFiles() {
        return sortListFiles(this.alls);
    }
    async initListFiles() {
        const path = DashboardHelper.getPath();
        const allFiles = await StreamApi.getAllFiles(path).toPromise();
        const allCaches = await ConversionApi.getAllCache().toPromise();
        const rootChilds: StreamFile[] = [];
        for (let i = 0; i < allFiles.length; i++) {
            const file = this.convertListFile(allFiles[i], allCaches, path);
            if (file) {
                rootChilds.push(file);
            }
        }
        return 'done';
    }

    async cacheFile(data: StreamFile): Promise<FileInfoResponse> {
        const file = await ConversionApi.cacheFile(data).toPromise();
        return file;
    }
    async uploadFile(data: any, path: string): Promise<string> {
        const file = await ConversionApi.uploadFile(data, path).toPromise();
        return file;
    }
    static addFolderExtend(path: string) {
        return ConversionApi.addFolder(path)
    }
    convertListFile(file: any, allCaches: any[], path = ""): StreamFile | null {
        if (file.isDirectory === true) {
            const data: StreamFile = {
                filename: file.name,
                baseFileId: Communicator.UUID.create(),
                baseMajorRev: 0,
                baseMinorRev: 0,
                isChecked: false,
                status: file.status,
                child: [],
                isDirectory: file.isDirectory,
                createdDate: '',
                cacheStatus: 0,
                modelFileId: '',
                id: '',
                originalFilePath: file.originalFilePath
            };
            if (file.child) {
                const arr: any[] = file.child;
                for (let i = 0; i < arr.length; i++) {
                    const child = arr[i];
                    const childFile = this.convertListFile(child, allCaches);
                    if (childFile) {
                        data.child.push(childFile);
                    }
                }
                // Sort foder first
                data.child = sortListFiles(data.child);
            }

            const check = reg.test(data?.filename);
            if (!check) {
                this.alls.push(data)
            }

            return data;
        }
        else {
            const re = allCaches.find((item: any) => item.filename === file.name
                && ((path && (item.originalFilePath === path || (!item.originalFilePath && path === '.')))
                    || (file.originalFilePath === item.originalFilePath)));
            if (re) {
                const data: StreamFile = {
                    filename: file.name, status: file.status, isDirectory: file.isDirectory,
                    baseFileId: Communicator.UUID.create(), child: [], ...re
                };
                const check = reg.test(data?.originalFilePath?.split('/')?.pop() || '');
                if (!check) {
                    this.alls.push(data)
                }
                return data;
            }
            else {
                const data: StreamFile = {
                    filename: file.name,
                    status: file.status,
                    isDirectory: file.isDirectory,
                    baseFileId: Communicator.UUID.create(),
                    child: [],
                    cacheStatus: 0,
                    baseMajorRev: 0,
                    baseMinorRev: 0,
                    isChecked: false,
                    createdDate: '',
                    modelFileId: '',
                    id: '',
                    originalFilePath: file.originalFilePath
                };
                const check = reg.test(data?.originalFilePath?.split('/')?.pop() || '');
                if (!check) {
                    this.alls.push(data)
                }
                return data;
            }
        }
    }
}
